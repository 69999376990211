@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

$primary-text-color: #323438;
$secondary-text-color: #7f828a;

body {
  font-family: "Poppins", sans-serif;
  background: #fff;
  color: $primary-text-color;
}

/* general */

a {
  text-decoration: none;
  color: unset;
}

p {
  font-size: 17px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  line-height: 1.5rem;
}

.bold {
  font-weight: 800;
}

.hidden {
  display: none;
}

.clickable {
  cursor: pointer;
}

.noselect {
  user-select: none;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.italic {
  font-style: italic;
}

.title {
  letter-spacing: 2px;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
}

.text-small {
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  font-size: 0.9rem;
}

.bold {
  font-weight: bold;
}

.home-title {
  @extend .title;

  margin-bottom: 0px;
  margin-top: 2rem;
}

/* flex */

.flex {
  display: flex;
}

.h-flex {
  flex-direction: row;
}

.v-flex {
  flex-direction: column;
}

.ai-flex {
  align-items: center;
}

.jc-flex {
  justify-content: center;
}

/* only mobile or desktop */

.mobile {
  visibility: hidden;
}

.desktop {
  visibility: visible;
}

@media screen and (max-width: 900px) {
  .mobile {
    visibility: visible;
  }

  .desktop {
    visibility: hidden;
  }
}

/* --- navbar --- */
$navbar-width: 21.5rem;

.navbar {
  position: fixed;
  width: $navbar-width;
  height: 100%;
  /*background-color: black;*/
  color: black;
  top: 0px;

  .nav-links {
    width: 80%;
    margin: 5rem;
    margin-top: 7rem;
    margin-left: 3rem;
    list-style: none;
    font-weight: 800;
    font-size: 17px;
    letter-spacing: 2px;
    line-height: 3rem;

    .nav-link {
      a {
        color: black;
        text-decoration: none;
      }
    }

    .submenu-link {
      a {
        color: rgb(128, 59, 30);
        text-decoration: none;
        padding-left: 1rem;
      }
    }
  }
}

.close-navbar {
  @extend .shadow;

  position: fixed;
  width: 3rem;
  height: 3rem;
  background-color: rgb(255, 255, 255);
  top: 2rem;
  right: 0.7rem;
  z-index: 35;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}

.content-hidden {
  max-height: 0;
  transition: max-height 0.25s ease;
  overflow: hidden;
  position: relative;
}

.content {
  max-height: 1000px;
  transition: max-height 0.5s ease;
  overflow: hidden;
  position: relative;
}

/* --- main section --- */
.main {
  width: 100%;
  height: 50rem;
  .main-section {
    padding-left: $navbar-width;
  }
}

.page-section {
  background-color: #fff;
  padding: 2rem 3rem 3rem 3rem;
  max-width: 90rem;
  margin: auto;
}

.shadow {
  -webkit-box-shadow: 3px 8px 28px rgba(40, 60, 80, 0.15);
  box-shadow: 3px 8px 28px rgba(40, 60, 80, 0.15);
}

.responsive-box {
  /*border: 3px black solid;*/
  /*border: 50px solid transparent;
  border-image: url(b4.png) 10% round;*/
  width: 100%;
}

.padding-box {
  @extend .responsive-box;
  margin-top: 2rem;
  padding: 2rem;
}

.about-box {
  margin: auto;
  max-width: 50rem;
  text-align: justify;
}

.video-box {
  position: relative;
  padding: 56.25% 0px 0px;
  opacity: 1;
}

.front-image-box {
  display: flex;

  img {
    width: 100%;
  }
}

.instagram-box {
  padding-left: 2rem;
  padding-right: 2rem;
  display: flex;
  flex-direction: column;
  .header {
    display: flex;
    align-items: center;
    justify-content: center;

    .logo {
      width: 10rem;
      padding: 2rem;
      padding-left: 0rem;
      border-radius: 50%;
    }
  }

  .follow-me {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    @extend .title;
    @extend .bold;
    text-decoration: underline;
    color: black;
    .icon {
      padding: 0.3rem;
      font-size: 2.6rem;
    }
  }

  .instagram-grid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    .post {
      width: 15vw;
      height: 15vw;
      padding: 0.5rem;
      object-fit: cover;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .image {
        width: 100%;
        height: 100%;
      }

      .play {
        font-size: 3rem;
        color: rgb(255, 255, 255);
        position: absolute;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .instagram-box {
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    .header {
      flex-direction: column;

      .logo {
        padding: 0.5rem;
      }

      .title {
        display: none;
      }
    }

    .follow-me {
      p {
        font-size: 13px;
      }
    }

    .instagram-grid {
      .post {
        width: 23vw;
        height: 23vw;

        .play {
          font-size: 1.7rem;
        }
      }
    }
  }

  .hidden-mobile {
    display: none;
  }

  .page-section {
    padding: 1.5rem;
  }

  .navbar {
    position: fixed;
    width: 100%;
    min-width: 100%;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 30;
    overflow-y: auto;

    .nav-links {
      display: block;
      margin: auto;
      width: 16rem;
      margin-top: 5rem;
    }
  }

  .close-navbar {
    opacity: 1;
  }

  .main {
    .main-section {
      padding: 0px;
    }
  }
}

.langauge-flags {
  display: flex;
  height: 2.5rem;

  .it-label,
  .en-label {
    font-size: 1.3rem;
    margin-right: 0.7rem;
    color: #56585e;
    cursor: pointer;

    &.selected {
      color: #1f1f1f;
    }

    &:hover {
      color: #323438;
    }
  }

  img {
    padding: 0.4rem;
  }
}

/* works */

.works {
  padding: 1rem;

  .works-toolbar {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    list-style: none;

    li {
      padding: 0.6rem;
      cursor: pointer;
    }

    .clicked {
      border-bottom: 3px solid black;
    }
  }
}

.work-image {
  width: 100%;
  padding: 1.5rem;
}

.works-popup {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.753);
  overflow: scroll;
  padding-bottom: 20rem;

  .content-box {
    background-color: white;
    position: relative;
    top: 20%;
    left: 10%;
    width: 80%;
    padding: 3rem;
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 900px) {
  .works-popup {
    .content-box {
      padding: 1.5rem;
    }
  }

  .work-image {
    padding: 0.5rem;
  }
}

.images {
  padding: 1rem;
  flex-wrap: wrap;
}

/* contact form */
.contact-form {
  display: flex;
  flex-direction: column;
  h3 {
    font-size: 18px;
  }

  font-size: 17px;

  .row-contact {
    justify-content: space-between;
    padding-top: 1.5rem;

    .form-element {
      width: 50%;
      .form-input {
        display: block;
        width: 90%;
        font-size: inherit;

        border: none;
        outline: none;
        border-bottom: 1px solid gray;
        padding-bottom: 1rem;
        padding-top: 1rem;
      }

      .form-checkbox {
        padding-bottom: 1rem;
        padding-top: 1rem;
        padding-right: 2rem;
        margin-right: 2rem;
      }
    }
  }
  .form-button {
    padding-bottom: 0.3rem;
    padding-top: 1rem;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid black;
    font-size: inherit;
  }

  .form-button:hover {
    color: rgba(0, 0, 0, 0.575);
  }

  .contact-error {
    font-size: 15px;
    margin-top: 0.3rem;
    color: #ec2c2c;
  }
  .form-textarea {
    font-family: inherit;
    font-size: inherit;

    border: none;
    overflow: auto;
    outline: none;

    background-image: linear-gradient(to right, #fff 1px, transparent 1px),
      linear-gradient(to left, #fff 1px, transparent 1px),
      repeating-linear-gradient(
        #fff,
        #fff 45px,
        #ccc 45px,
        #ccc 46px,
        #fff 46px
      ) !important;
    line-height: 46px !important;
    min-height: 15rem;
    min-width: 100%;
    max-width: 100%;
  }

  .error_message,
  .response_message {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: 0.5rem;
    }
  }
}

.footer {
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  bottom: 0rem;
  padding: 1.5rem;
  margin-left: 2rem;
  margin-right: 2rem;

  a {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.575);
  }
}

.button {
  color: rgba(0, 0, 0, 0.7);
  border-bottom: 2px solid rgba(0, 0, 0, 0.7);
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

.image-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1.1rem;

  .image-item {
    height: 100%;
  }
}

/* SLIDING TOOLBAR */

.sliding-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .sliding-box {
    overflow-x: scroll;
    border-bottom: 1px solid #32343864;
    scrollbar-width: none;
  }

  .row-btn {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    font-size: 2.2rem;
    color: #56585e;
    transition: all 0.5s;
    :hover {
      color: #323438;
    }
  }
}

.sliding-toolbar::-webkit-scrollbar {
  display: none;
}

@media screen and (min-width: 900px) {
  .sliding-toolbar {
    justify-content: space-around;
    width: 100%;

    .sliding-box {
      width: 100%;
    }
    .row-btn {
      display: none;
    }
  }

  .works {
    padding: 1rem;

    .works-toolbar {
      width: 100%;
      justify-content: space-around;
    }
  }
}

.hline {
  width: 80%;
  color: gray;
  border-bottom: 1px solid gray;
}

.nav-social-box {
  display: flex;
  align-items: center;
  p {
    padding-left: 0.5rem;
    padding-bottom: 0.5rem;
    color: black;
    font-size: 0.75rem;
  }
}

.social-icon {
  font-size: 1.75rem;
  color: black;
}
